import React from "react"
// import { Link } from "gatsby"

// import Image from "../image"
// import SignupModalTrigger from '../home/signupModal'

// shows the header for blog posts
const Header = ({title, subtitle, subsubtitle, description}) => {

  return (
    <div className="">
      <h1 className="text-3xl text-center font-extrabold tracking-tight sm:text-4xl text-center">{title}</h1>
      {subtitle ? (
        <div className="text-base md:text-xl text-center text-white font-semibold mt-4 opacity-80">{subtitle}</div>
      ) : ''}
      {subsubtitle ? (
        <div className="text-sm md:text-base opacity-70 text-center text-white font-semibold mt-2">{subsubtitle}</div>
      ) : ''}
      <hr className="border-gray-600"/>
      {description ? (
        <React.Fragment>
          <div className="max-w-xl mx-auto text-sm md:text-base opacity-70 text-center text-white font-semibold mt-2">{description}</div>
          <br/>
        </React.Fragment>
      ) : ''}
    </div>
  );
}

export default Header;
