import React from "react"
import { Link } from "gatsby"
// import FamewallEmbed from 'react-famewall'

import Layout from "../components/layout"
import SEO from "../components/seo"

// import ReviewSections from "../components/reviews/sections";
// import CTA from "../components/pricing/cta";
import Header from '../components/blog/header';


const AffiliatesPage = ({path}) => (
  <Layout
    title="Affiliates" subtitle={"Join the GummySearch affiliate program"}
    includeBanner={false} lightHeader={false}
    seoComponent={<SEO title="GummySearch Affiliates" description="Join the GummySearch affiliate program" path={'/affiliates/'} generateImage={true} />}
  >
    <div className="content-body markdown-body">
      <Header title="Affiliate Program" subtitle={"Make money introducing your audience to the #1 research tool for Reddit"} />

      <h2>Affiliate program details</h2>
      <ul className="mb-4">
        <li>Receive a <b>40% commission</b> for <b>every</b> payment a referred customer makes</li>
        <li>Share the <Link to="/product/">GummySearch product</Link>, <Link to="/blog/">blog posts</Link>, or <Link to="/tools/">free tools</Link> behind your link to make referrals</li>
      </ul>
      <div className="flex space-x-4">
        <a href="https://gummysearch.getrewardful.com/signup" target="_blank" className="mt-2">
          <button className="bg-cyan-500 text-white font-bold px-6 py-3 rounded-md">Join the program now</button>
        </a>
        <a href="https://gummysearch.getrewardful.com/login" target="_blank" className="mt-2">
          <button className="bg-gray-600 text-white font-bold px-6 py-3 rounded-md">Log into your dashboard</button>
        </a>
      </div>

      

      <h2>Why join the GummySearch affiliate program</h2>
      <ul>
        <li>Introduce your friends/audience to the #1 research tool for Reddit</li>
        <li>Help them accelerate their business with premium audience insights</li>
        <li>Make potentially thousands of dollars a month with a competitive program (40% for life)</li>
        <li>Support GummySearch's bootstrapped startup journey</li>
      </ul>


      <h2>How the affiliate program works</h2>
      <ol>
        <li>1. Sign up for the affiliate program <a href="https://gummysearch.getrewardful.com/">here</a>, it takes less than a minute</li>
        <li>2. Share your unique link on your blog, website, newsletter, socials</li>
        <li>3. Your friends sign up for the free or premium version of GummySearch via your link</li>
        <li>4. For any paid referrals you make, you'll get paid automatically at the beginning of the next month (once you hit the $20 minimum)</li>
      </ol>

      <h2>How to succeed as a Gummy affiliate</h2>
      <ul>
        <li>Share your affiliate link with a testimonial of why you like the tool</li>
        <li>Share the <Link to="/tools/">free tools</Link> we build, or any <Link to="/blog/">blog posts</Link> you like (just add your ?via= code to the urls)</li>
        <li>Link your affiliate code to your GummySearch account, so your shareable reports automatically include your referral link</li>
        <li>Email Fed if you have any ideas on how to improve the affiliate program</li>
      </ul>

      <h2>Rules around the affiliate program</h2>
      <div>Violations could cause you to be kicked out of the affiliate program</div>
      <ul>
        <li>Don't pretend to be a GummySearch employee</li>
        <li>Don't purchase ads around the "GummySearch" brand name (Referrals from Google ads will be ignored)</li>
        <li>Don't use redirect services (like Shareasale) to mask your referral origin</li>
        <li>Don't sign up using your own affiliate link (we'll know)</li>
      </ul>
      <div>If you have any questions/doubts about these, just contact us and ask about it.</div>

    </div>
  
  </Layout>
)


export default AffiliatesPage;
